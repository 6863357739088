var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header')])], 2), _c('div', {
    staticClass: "container",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "xl": "9"
    }
  }, [_c('div', {
    staticClass: "mt-4 mb-4 text-left"
  }, [_c('span', {
    staticStyle: {
      "font-size": "2.5rem",
      "color": "black"
    }
  }, [_c('b', [_vm._v(" Cari "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("E-book")]), _vm._v(" yang Kamu Butuhkan ")])]), _c('br'), _c('h4', {
    staticClass: "mt-2"
  }, [_vm._v(" Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam quaerat ad, quasi placeat officiis laborum! Iure suscipit accusantium cumque quibusdam? ")]), _c('b-form-group', {
    staticClass: "blog-search mt-2",
    staticStyle: {
      "width": "400px"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "search-input",
      "placeholder": "Search here"
    },
    model: {
      value: _vm.q,
      callback: function callback($$v) {
        _vm.q = $$v;
      },
      expression: "q"
    }
  }), _c('b-input-group-append', {
    staticClass: "cursor-pointer",
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1)], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "6",
      "xl": "3"
    }
  }, [_c('div', {
    staticClass: "container mt-3"
  }, [_c('b-img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": require('@/assets/images/elp/header-ebook.png'),
      "alt": "Snow"
    }
  })], 1)])], 1)], 1), _c('div', {
    staticClass: "container",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-col', {
    staticClass: "text-left",
    attrs: {
      "md": "6",
      "xl": "10"
    }
  }, [_c('h6', {
    staticClass: "mb-1 mt-2"
  }, [_c('b', [_vm._v("Paling Banyak Dicari")])])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6",
      "xl": "2"
    }
  }, [_c('h6', {
    staticClass: "mb-1 mt-2"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'e-book-banyak-dicari'
      }
    }
  }, [_c('b', [_vm._v("Lihat Semua")])])], 1)])], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_vm.data[0] == null ? _c('div', {
    staticClass: "text-center mt-5 mb-5"
  }, [_c('b-img', {
    attrs: {
      "src": require('@/assets/images/elp/empty.png'),
      "fluid": "",
      "width": "200px"
    }
  }), _c('h5', {
    staticClass: "text-primary"
  }, [_vm._v("E-book tidak ditemukan!!")])], 1) : _c('b-row', {
    staticClass: "blog-list-wrapper"
  }, _vm._l(_vm.currentPageItems, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "md": "3",
        "xl": "2"
      }
    }, [_c('b-card', {
      staticStyle: {
        "height": "350px"
      },
      attrs: {
        "tag": "article",
        "no-body": ""
      }
    }, [_c('b-container', {
      staticClass: "bg-light-secondary",
      staticStyle: {
        "text-align": "center",
        "padding": "10px 25px 10px"
      },
      attrs: {
        "fluid": ""
      }
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'e-book-detail',
          params: {
            id: item.slug
          }
        }
      }
    }, [_c('b-img', {
      staticClass: "card-img-top",
      staticStyle: {
        "height": "150px",
        "object-fit": "cover",
        "width": "100%"
      },
      attrs: {
        "src": _vm.apiFile + item.photo,
        "alt": "".concat(item.name, "-").concat(item.id)
      }
    })], 1)], 1), _c('b-card-body', [_c('b-card-title', {
      staticStyle: {
        "font-size": "0.75rem",
        "text-align": "left"
      }
    }, [_c('b-link', [_c('b-badge', {
      staticClass: "mr-75",
      attrs: {
        "pill": "",
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(item.category.name) + " ")])], 1)], 1), _c('b-card-text', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top.v-primary",
        value: item.name,
        expression: "item.name",
        modifiers: {
          "hover": true,
          "top": true,
          "v-primary": true
        }
      }, {
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "item-content-truncate text-dark text-left",
      attrs: {
        "title": "tooltip on top",
        "variant": "primary"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('hr'), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [item.price > 0 ? _c('b-link', {
      staticClass: "font-weight-bold",
      attrs: {
        "to": {
          name: 'e-book-detail',
          params: {
            id: item.slug
          }
        }
      }
    }, [_c('b', [_vm._v("Rp. " + _vm._s(_vm.formatRupiah(item.price)))])]) : _c('b-link', {
      staticClass: "font-weight-bold",
      attrs: {
        "to": {
          name: 'e-book-detail',
          params: {
            id: item.slug
          }
        }
      }
    }, [_vm._v(" Gratis ")])], 1)], 1)], 1)], 1);
  }), 1), _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalData,
      "per-page": _vm.filters.perPage,
      "first-number": "",
      "align": "center",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.page,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "page", $$v);
      },
      expression: "filters.page"
    }
  })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "text-left",
    attrs: {
      "md": "6",
      "xl": "10"
    }
  }, [_c('h6', {
    staticClass: "mb-1 mt-2"
  }, [_c('b', [_vm._v("Gratis Untuk Kamu")])])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6",
      "xl": "2"
    }
  }, [_c('h6', {
    staticClass: "mb-1 mt-2"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'e-book-gratis'
      }
    }
  }, [_c('b', [_vm._v("Lihat Semua")])])], 1)])], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loadingG
    }
  }, [_vm.dataGratis[0] == null ? _c('div', {
    staticClass: "text-center mt-5 mb-5"
  }, [_c('b-img', {
    attrs: {
      "src": require('@/assets/images/elp/empty.png'),
      "fluid": "",
      "width": "200px"
    }
  }), _c('h5', {
    staticClass: "text-primary"
  }, [_vm._v("E-book tidak ditemukan!!")])], 1) : _c('b-row', {
    staticClass: "blog-list-wrapper"
  }, _vm._l(_vm.currentPageItemsG, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "md": "3",
        "xl": "2"
      }
    }, [_c('b-card', {
      staticStyle: {
        "height": "350px"
      },
      attrs: {
        "tag": "article",
        "no-body": ""
      }
    }, [_c('b-container', {
      staticClass: "bg-light-secondary",
      staticStyle: {
        "text-align": "center",
        "padding": "10px 25px 10px"
      },
      attrs: {
        "fluid": ""
      }
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'e-book-detail',
          params: {
            id: item.slug
          }
        }
      }
    }, [_c('b-img', {
      staticClass: "card-img-top",
      staticStyle: {
        "height": "150px",
        "object-fit": "cover",
        "width": "100%"
      },
      attrs: {
        "src": _vm.apiFile + item.photo,
        "alt": "".concat(item.name, "-").concat(item.id)
      }
    })], 1)], 1), _c('b-card-body', [_c('b-card-title', {
      staticStyle: {
        "font-size": "0.75rem",
        "text-align": "left"
      }
    }, [_c('b-link', [_c('b-badge', {
      staticClass: "mr-75",
      attrs: {
        "pill": "",
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(item.category.name) + " ")])], 1)], 1), _c('b-card-text', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top.v-primary",
        value: item.name,
        expression: "item.name",
        modifiers: {
          "hover": true,
          "top": true,
          "v-primary": true
        }
      }, {
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "item-content-truncate text-dark text-left",
      attrs: {
        "title": "tooltip on top",
        "variant": "primary"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('hr'), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [item.price > 0 ? _c('b-link', {
      staticClass: "font-weight-bold",
      attrs: {
        "to": {
          name: 'e-book-detail',
          params: {
            id: item.slug
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.formatRupiah(item.price)) + " ")]) : _c('b-link', {
      staticClass: "font-weight-bold",
      attrs: {
        "to": {
          name: 'e-book-detail',
          params: {
            id: item.slug
          }
        }
      }
    }, [_c('b', [_vm._v("Gratis")])])], 1)], 1)], 1)], 1);
  }), 1), _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalDataGratis,
      "per-page": _vm.filtersG.perPage,
      "first-number": "",
      "align": "center",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filtersG.page,
      callback: function callback($$v) {
        _vm.$set(_vm.filtersG, "page", $$v);
      },
      expression: "filtersG.page"
    }
  })], 1)], 1)], 1)], 1), _c('h6', {
    staticClass: "section-label mb-1 mt-2"
  }, [_vm._v("Kategori")]), _c('b-row', {
    staticClass: "blog-list-wrapper"
  }, _vm._l(_vm.dataKategoriEbook, function (item, i) {
    return _c('b-col', {
      key: i,
      staticClass: "d-flex justify-content-start align-items-center",
      attrs: {
        "md": "auto"
      }
    }, [_c('b-card-group', {
      staticClass: "blog-categories"
    }, [_c('b-card', {
      staticClass: "text-center",
      attrs: {
        "tag": "kategori",
        "no-body": ""
      }
    }, [_c('b-card-body', [_c('b-card-text', {
      staticClass: "truncate align-center"
    }, [_c('b-link', {
      attrs: {
        "to": "/ebook/category/".concat(item.id)
      }
    }, [_c('b-img', {
      staticStyle: {
        "max-height": "50px"
      },
      attrs: {
        "src": item.icon ? _vm.apiFile + item.icon : require('@/assets/images/elp/no-image.jpg')
      }
    })], 1), _c('div', {
      staticClass: "blog-category-title text-body mt-2"
    }, [_c('b-badge', {
      attrs: {
        "variant": item.color ? item.color : 'primary'
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1)]), _c('footer-home')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }